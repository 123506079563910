.pagination {
  margin: auto;
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
}

.pagination a {
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid var(--text-color);
  border-radius: 5px;
  color: var(--text-color);
}

.pagination-link {
  font-weight: bold;
}

.pagination-link-active a {
  color: white !important;
  background: var(--primary-color);
}

.pagination-link-disabled a {
  color: var(--text-color);
  border: 1px solid;
}
