.branch .menu-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.branch .menu-buttons button {
  margin-left: 10px;
}

.branch.update-dialog input {
  width: 300px;
  padding: 10px;
  margin-top: 10px;
}
