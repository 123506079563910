.review-footer-container {
  align-items: top;
  display: flex;
  justify-content: space-between;
  background-color: var(--body-color);
  flex-wrap: nowrap;
  padding: 10px;
}

.review-footer-container .footer-left {
  display: flex;
  align-items: top;
  flex-flow: nowrap;
}

.review-footer-container .footer-center {
  display: flex;
  align-items: top;
  flex-wrap: nowrap;
}

.review-footer-container .footer-right {
  display: flex;
  align-items: top;
  flex-wrap: nowrap;
}

.review-footer-container button {
  color: var(--text-color);
  background-color: white;
  border: none;
  cursor: pointer;
}

.review-footer-container button.selected {
  color: white;
  font-weight: bold;
  background-color: var(--primary-color);
}

.review-footer-container .icon-label {
  padding-left: 10px;
  padding-right: 10px;
}
