.draft-table-list-container {
  display: flex;
  justify-content: flex-start;
}

.draft-table-list-container button {
  height: 35px;
}

.draft-table-list-container .table-list {
  width: 90%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
  padding-left: 0;
  margin: 0 10px;
  position: absolute;
  background-color: white;
}

.draft-table-list-container .table-list li {
  list-style: none;
  white-space: nowrap;
  padding-top: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  color: var(--text-color);
  background-color: white;
  cursor: pointer;
  font-size: 14px;
}

.draft-table-list-container .table-list li.selected,
.draft-table-list-container .table-list li.selected:hover {
  cursor: default;
  color: white;
  background-color: var(--primary-color);
}

.draft-table-list-container .table-list li .icon.caret.table {
  cursor: pointer;
  border-radius: 5px;
  width: 40px;
  height: 20px;
}

.draft-table-list-container .table-list li .icon.caret.table:hover {
  cursor: pointer;
  background-color: white;
  color: black;
}

.draft-table-list-container .table-list li:hover {
  color: var(--text-color);
  background-color: var(--primary-color-light);
}
