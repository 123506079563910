.user-container {
  min-height: 0;
  height: 100%;
  width: 100%;
}

.user-by-file.share-dialog {
  overflow: hidden;
  padding: 10px;
}

.user-container .grid-toolbar {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user-container .center-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-start;
}

.user-container .left-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-start;
}

.user-container .right-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-end;
}

.user-container .icon-label {
  padding-left: 10px;
  padding-right: 10px;
}
