.home-container .sidebar .close {
  width: 73px;
  overflow: hidden;
}

.home-container .sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  background: var(--sidebar-color);
  transition: var(--tran-05);
  height: 100vh;
}

.home-container .content.open {
  left: 73px;
  width: calc(100% - 73px);
}

.home-container .content {
  transition: var(--tran-05);
  position: absolute;
  top: 0;
  left: 250px;
  width: calc(100% - 250px);
  height: 100vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.home-container .file-data {
  height: calc(100% - 30px);
  width: 100%;
  overflow: hidden;
}
