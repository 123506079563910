.empty-grid {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--body-color);
  position: absolute;
}

.empty-grid h5 {
  border: 0.5px solid var(--text-color);
  border-radius: 5px;
  padding: 25px 50px;
  font-weight: normal;
}
