.sidebar-container {
  height: 100vh;
  display: flex;
  align-items: left;
  justify-content: flex-between;
  flex-direction: column;
}

.sidebar-container .header {
  flex: 1;
}

.sidebar-container .center {
  flex: 10;
  overflow-y: scroll;
  border-top: 0.5px solid var(--text-color);
  border-bottom: 0.5px solid var(--text-color);
}

.sidebar-container .footer {
  flex: 1;
}

.sidebar-container .menu-bar {
  padding: 0;
}

.sidebar-container .center .menu-bar,
.sidebar-container .footer .menu-bar {
  padding-top: 0;
  padding-bottom: 0;
}

.sidebar-container .center::-webkit-scrollbar {
  display: none;
}

.sidebar-container .text {
  font-size: 17px;
  font-weight: 500;
  white-space: nowrap;
  opacity: 1;
}

.sidebar-container li .text {
  padding-left: 8px;
}

.sidebar-container li {
  display: flex;
  align-items: center;
  height: 40px;
  list-style: none;
  color: var(--text-color);
  margin: 5px;
  border-radius: 5px;
}

.sidebar-container li:first-child {
  margin-top: -10px;
  padding-top: 0;
}

.sidebar-container li:hover {
  color: var(--sidebar-color);
  background-color: var(--primary-color);
  cursor: pointer;
}

.close .sidebar-container li:hover {
  color: var(--sidebar-color);
  background-color: var(--primary-color);
  cursor: pointer;
  width: 63px;
}

.sidebar-container .icon {
  min-width: 60px;
  width: 40px;
  border-radius: 6px;
}

.sidebar-container .brand-icon {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 18px;
}

.sidebar-container .brand-container {
  align-items: center;
  display: flex;
  height: 66px;
  cursor: pointer;
}

.sidebar-container .brand-label {
  color: var(--text-color);
  font-size: 18px;
  font-weight: 600;
}

.sidebar-container .sidebar-toggle {
  transition: var(--tran-05);
  height: 25px;
  width: 25px;
  color: var(--primary-color);
  position: absolute;
  top: 25px;
  left: 235px;
  z-index: 1000;
  cursor: pointer;
  border-radius: 50%;
}

.sidebar-container .sidebar-toggle.close {
  left: 35px;
}

.sidebar-container .link-container {
  align-items: center;
  display: flex;
  width: 240px;
}
