dialog {
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
  cursor: pointer;
  transition: transform var(--tran-05);
}

dialog:hover {
  transform: translateY(-5px);
}
