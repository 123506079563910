.master-container .sidebar .close {
  width: var(--sidebar-close);
  overflow: hidden;
}

.master-container .sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  background: var(--sidebar-color);
  transition: var(--tran-05);
  height: 100vh;
}

.master-container .content.open {
  left: var(--sidebar-close);
  width: calc(100% - var(--sidebar-close));
}

.master-container .content {
  transition: var(--tran-05);
  position: absolute;
  top: 0;
  left: var(--sidebar-open);
  width: calc(100% - var(--sidebar-open));
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.master-container .master-data {
  height: calc(100% - 90px);
}

.master-container .master-footer {
  width: calc(100% - var(--sidebar-close));
  background-color: var(--body-color);
  height: 50px;
}
