.url-container {
  padding: 5px;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: center;
  height: 40px;
  border: 0.5px solid var(--text-color);
  border-radius: 5px;
  background-color: white;
  width: 99%;
}

.url-send-container {
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  border: 0.5px solid var(--text-color);
  border-radius: 5px;
  width: 70px;
}

.url-action-container {
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
}

.url-text {
  width: 80%;
  color: var(--text-color);
  border-style: none;
  height: 100%;
  z-index: 100;
}

.url-get {
  padding-left: 10px;
}

.url-send {
  width: 10%;
  height: 100%;
}
