.review-header-container {
  align-items: top;
  display: flex;
  justify-content: space-between;
  background-color: var(--body-color);
  flex-wrap: nowrap;
  padding: 10px;
}

.review-header-container .header-left {
  display: flex;
  align-items: top;
  flex-flow: nowrap;
}

.review-header-container .header-center {
  display: flex;
  align-items: top;
  flex-wrap: nowrap;
}

.review-header-container .header-right {
  display: flex;
  align-items: top;
  flex-wrap: nowrap;
}

.review-header-container button {
  display: flex;
  align-items: center;
  color: var(--text-color);
  background-color: white;
  border: none;
  cursor: pointer;
}

.review-header-container button.selected {
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  background-color: var(--primary-color);
}

.review-header-container .icon-label {
  padding-left: 10px;
  padding-right: 10px;
  max-width: 200px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
