.submit-container .submit-item {
  list-style: none;
  color: var(--text-color);
  width: 100%;
}

.submit-container {
  height: 100%;
  background-color: white;
}

.submit-container .grid-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.submit-container .center-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-start;
}

.submit-container .left-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-start;
}

.submit-container .right-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-end;
  align-items: center;
}

.submit-container .icon-label {
  padding-left: 10px;
  padding-right: 10px;
}

.submit-container .submit-item-container {
  display: flex;
  align-items: flex-start;
  border-top: 0.5px solid var(--text-color);
  border-left: 0.5px solid var(--text-color);
  border-right: 0.5px solid var(--text-color);
}

.submit-container .submit-item-container:last-child {
  display: flex;
  align-items: flex-start;
  border-bottom: 0.5px solid var(--text-color);
  border-left: 0.5px solid var(--text-color);
  border-right: 0.5px solid var(--text-color);
}

.submit-container .submit-message-container {
  display: flex;
  justify-content: space-between;
  align-items: top;
  width: 100%;
}

.submit-container .submit-message-container .icon {
  margin-right: 5px;
  margin-left: 5px;
}

.submit-container .submit-list {
  height: 200px;
  overflow: auto;
  padding: 0;
}

.submit-container .submit-item .commit-list {
  padding: 0;
  background-color: var(--primary-color-light);
  max-height: 100px;
  overflow: auto;
}

.submit-container .submit-message-container.selected {
  background-color: var(--primary-color);
  color: white;
}

.submit-container .select-all-container {
  display: flex;
  color: var(--text-color);
}
