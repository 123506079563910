.filter-cell {
  line-height: 35px;
  padding: 0;

  > div {
    padding-block: 0;
    padding-inline: 8px;

    &:first-child {
      border-block-end: 1px solid var(--rdg-border-color);
    }
  }
}

.rdg-cell {
  color: var(--text-color);
}

.even-row .row-button,
.even-row {
  background-color: var(--primary-color-light);
}

.selected-row .row-button,
.selected-row .rdg-cell {
  color: white;
  font-weight: bold;
  background-color: var(--primary-color);
}
