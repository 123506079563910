.branch-container {
  height: 100%;
  background-color: white;
}

.branch-container .grid-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.branch-container .center-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-start;
}

.branch-container .left-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-start;
}

.branch-container .right-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-end;
}

.branch-container .icon-label {
  padding-left: 10px;
  padding-right: 10px;
}

.branch-container .grid-action {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
