.draft-connect-container .sidebar .close {
  width: var(--sidebar-close);
  overflow: hidden;
}

.draft-connect-container .sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  background: var(--sidebar-color);
  transition: var(--tran-05);
  height: 100vh;
}

.draft-connect-container .content.open {
  left: var(--sidebar-close);
  width: calc(100% - var(--sidebar-close));
}

.draft-connect-container .content {
  transition: var(--tran-05);
  position: absolute;
  top: 0;
  left: var(--sidebar-open);
  width: calc(100% - var(--sidebar-open));
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.draft-connect-container .connect-data {
  height: calc(100% - 100px);
  background-color: var(--body-color);
  padding: 5px;
}

.draft-connect-container .connect-data .resource-data {
  height: calc(100% - 60px);
  background-color: var(--body-color);
  padding: 5px;
}

.draft-connect-container .connect-data .resource-tab {
  padding: 5px;
  display: flex;
}

.draft-connect-container
  .connect-data
  .resource-tab
  .resource-label-container
  .icon {
  padding-right: 5px;
}

.draft-connect-container .connect-data .resource-tab .resource-label-container {
  background-color: white;
  color: var(--text-color);
  margin-right: 10px;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 5px;
}

.draft-connect-container .connect-footer {
  width: 100%;
  background-color: var(--body-color);
  height: 50px;
  padding: 10px;
}
