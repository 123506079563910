.home-header-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: var(--body-color);
  flex-wrap: nowrap;
  width: 100%;
}

.home-header-container .header-left {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 5px;
}

.home-header-container .header-right {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 5px;
}

.home-header-container .header-center {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 5px;
}

.home-header-container .search-container {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

.home-header-container .search-input {
  background: var(--primary-color-light);
  border-style: none;
  margin: 0;
  width: 200px;
  height: 20px;
  font-size: 15px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.home-header-container .search-input:focus {
  outline: none;
  font-size: 12px;
}

.home-header-container .search-icon {
  background: var(--primary-color-light);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 3px;
  margin-left: 5px;
}

.home-header-container .icon-label {
  padding-left: 10px;
  padding-right: 10px;
}

.home-header-container .icon.caret.group-by-user {
  border-radius: 5px;
  width: 20px;
}

.home-header-container .icon.caret.group-by-user:hover {
  background-color: white;
  color: black;
}
