.params-container {
  height: 100%;
  background-color: white;
}

.params-container .grid-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.params-container .center-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-start;
}

.params-container .left-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-start;
}

.params-container .right-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-end;
}

.params-container .icon-label {
  padding-left: 10px;
  padding-right: 10px;
}
