.filter-by-condition {
  display: flex;
  flex-direction: column;
}

.filter-by-condition input {
  padding-left: 10px;
}

.filter-by-condition .filter-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 3px;
}

.filter-by-condition .filter-buttons button {
  border: 0.5px solid var(--text-color);
  border-radius: 5px;
}
