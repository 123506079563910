del {
  text-decoration: line-through;
  text-decoration-color: red;
  text-decoration-thickness: 2px;
}

.old-value {
  color: black;
  background-color: rgb(255 232 200);
  padding-left: 5px;
  padding-right: 5px;
}

.new-value {
  color: black;
  background-color: rgb(172 215 147);
  padding-left: 5px;
  padding-right: 5px;
}

.change-tag {
  font-size: 10px;
  color: var(--text-color);
  background-color: var(--primary-color-light);
  border-radius: 5px;
  position: relative;
  top: -10px;
  right: -5px;
  border: 1px solid red;
}
