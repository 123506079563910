.draft-row-container.merge-diff {
  height: 100%;
  background-color: white;
}

.draft-row-container.merge-diff .grid-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.draft-row-container.merge-diff .center-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-start;
}

.draft-row-container.merge-diff .left-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-start;
}

.draft-row-container.merge-diff .right-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-end;
}

.draft-row-container.merge-diff .icon-label {
  padding-left: 10px;
  padding-right: 10px;
}
