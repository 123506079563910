.draft-row-resource-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  height: 100%;
  padding-top: 10px;
}

.draft-row-resource-url-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  margin-bottom: 10px;
}

.draft-row-resource-tab-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: white;
}

.draft-row-resource-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
}

.draft-row-resource-left .draft-row-resource {
  padding-right: 10px;
}

.draft-row-resource-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
}

.draft-row-resource-content-container {
  width: 100%;
  height: 90%;
}
