.register-container {
  display: flex;
  align-items: left;
  justify-content: center;
  background-color: white;
  margin-left: 50px;
  margin-right: 50px;
  flex-direction: column;
}

.register-container .register {
  display: flex;
  flex-direction: column;
}

.register-container .text {
  color: var(--text-color);
}

.register-container .text.error {
  color: var(--error);
  font-size: 12px;
}

.register-container .label-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  height: 5px;
}

.register-container .label-props {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  top: 20px;
  left: 20px;
  border-radius: 5px;
  padding: 10px;
}

.register-container .label-container label {
  padding-left: 10px;
}

.register-container .label-container .icon,
.register-container .label-container label {
  color: var(--text-color);
  background-color: white;
  display: inline;
  height: 20px;
  border-radius: 5px;
}

.register-container input.error {
  border-color: var(--error);
  border-width: 1px;
}

.register-container input.success {
  border-color: var(--success);
  border-width: 1px;
}

.register-container input {
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
  border-color: var(--text-color);
  width: 300px;
  border-width: 0.5px;
}

.register-container .menu-buttons {
  margin-bottom: 20px;
}
