.draft-table-list-container.merge-diff {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
}

.draft-table-list-container.merge-diff .table-list-container {
  margin-bottom: 35px;
}

.draft-table-list-container.merge-diff .table-list {
  width: calc(100% - var(--sidebar-open) + 100px);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
  overflow-y: hidden;
  padding-left: 0;
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 0;
  position: absolute;
  background-color: white;
}

.draft-table-list-container.merge-diff .table-list li {
  list-style: none;
  white-space: nowrap;
  padding-top: 6px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  color: var(--text-color);
  background-color: white;
  cursor: pointer;
  font-size: 14px;
}

.draft-table-list-container.merge-diff .table-list li.selected,
.draft-table-list-container.merge-diff .table-list li.selected:hover {
  color: white;
  cursor: default;
  background-color: var(--primary-color);
}

.draft-table-list-container.merge-diff .table-list li .icon.caret.table {
  cursor: pointer;
  border-radius: 5px;
  width: 100px;
}

.draft-table-list-container.merge-diff .table-list li .icon.caret.table:hover {
  cursor: pointer;
  background-color: white;
  color: black;
}

.draft-table-list-container.merge-diff .table-list li:hover {
  color: var(--text-color);
  background-color: var(--primary-color-light);
}

.draft-table-list-container.merge-diff button {
  height: 35px;
  width: 100px;
  margin: 0;
}

.draft-table-list-container.merge-diff .pagination {
  padding: 0;
  margin: 0;
}
