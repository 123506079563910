@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  font-family: Poppins, sans-serif;
}

:root {
  /* ===== Colors ===== */
  --body-color: #e4e9f7;
  --sidebar-color: #fff;
  --primary-color: #695cfe;
  --primary-color-light: #f6f5ff;
  --toggle-color: #ddd;
  --text-color: #707070;
  --warning: #f2a600;
  --success: #12c99b;
  --error: #e41749;

  /* ====== Transition ====== */
  --tran-02: 0.2s ease-in-out;
  --tran-03: 0.3s ease-in-out;
  --tran-04: 0.3s ease-in-out;
  --tran-05: 0.3s ease-in-out;

  /* ====== Sidebar props ====== */
  --sidebar-close: 73px;
  --sidebar-open: 250px;
}

body {
  min-height: 100vh;
  background-color: var(--body-color);
  transition: var(--tran-05);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::selection {
  background-color: var(--primary-color);
  color: #fff;
}

button {
  color: var(--text-color);
  background-color: white;
  border: none;
  cursor: pointer;
}

button.selected {
  color: white;
  font-weight: bold;
  background-color: var(--primary-color);
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
