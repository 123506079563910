.submit-master-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 0.5px solid var(--text-color);
  border-radius: 5px;
  background-color: white;
  color: var(--text-color);
}

.submit-master-card-container .card-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
}

.submit-master-card-container .center-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-start;
}

.submit-master-card-container .left-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-start;
}

.submit-master-card-container .right-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-end;
}

.submit-master-card-container .datacard-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: 350px;
  grid-gap: 10px;
  justify-content: center;
  padding: 10px 20px 20px;
  height: 95%;
  overflow-y: auto;
}

.submit-master-card-container .datacard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: top;
  padding: 20px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
  cursor: pointer;
  transition: transform var(--tran-05);
}

.submit-master-card-container .datacard:hover {
  transform: translateY(-5px);
}

.submit-master-card-container .datacard .tag {
  background-color: white;
  text-align: center;
  border-radius: 5px;
  color: var(--text-color);
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 38px;
}

.submit-master-card-container .datacard-name-tag {
  font-size: 16px;
  position: relative;
  top: -78px;
  min-width: 100px;
  max-width: 200px;
}

.submit-master-card-container .datacard-description-tag {
  position: relative;
  top: -106px;
  left: 10px;
  width: 100px;
}

.submit-master-card-container .datacard-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  text-overflow: ellipsis;
  height: 76px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.submit-master-card-container .datacard-description {
  padding: 10px;
  border: 0.5px solid var(--text-color);
  border-radius: 5px;
  margin-bottom: 10px;
  width: 90%;
  color: var(--text-color);
  resize: none;
  height: 100px;
}

.submit-master-card-container .datacard-user,
.submit-master-card-container .datacard-description,
.submit-master-card-container .datacard-createdat,
.submit-master-card-container .datacard-updatedat {
  font-size: 14px;
}

.submit-master-card-container .action-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.submit-master-card-container .row-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.submit-master-card-container .row-button:hover {
  background-color: var(--primary-color);
  color: white;
}

.submit-master-data {
  height: calc(100% - 30px);
  width: 100%;
  overflow: hidden;
}
