.rebase .menu-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.rebase .menu-buttons button {
  margin-left: 10px;
}

.rebase.add-dialog textarea {
  width: 350px;
  height: 100px;
  padding: 10px;
  margin-top: 10px;
}
