.alert {
  margin: 0;
  color: white;
  border-radius: 5px;
  border: none;
  background: none;
  padding: 5px;
  transition: transform var(--tran-05);
}

.alert-container {
  position: fixed;
  top: 1%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  height: 25px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  border: none;
}

.alert-success {
  background-color: var(--success);
}

.alert-error {
  background-color: var(--error);
}

.alert-warning {
  background-color: var(--warning);
}

.alert-info {
  background-color: var(--primary-color);
}
