.file-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 0.5px solid var(--text-color);
  border-radius: 5px;
  background-color: white;
  color: var(--text-color);
}

.file-card-container .card-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
}

.file-card-container .center-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-start;
}

.file-card-container .left-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-start;
}

.file-card-container .right-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-end;
}

.file-card-container .datacard-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: 250px;
  grid-gap: 10px;
  justify-content: center;
  padding: 0 20px 20px;
  height: 95%;
  overflow-y: auto;
}

.file-card-container .datacard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: top;
  padding: 20px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
  cursor: pointer;
  transition: transform var(--tran-05);
}

.file-card-container .datacard:hover {
  transform: translateY(-5px);
}

.file-card-container .datacard-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.file-card-container .datacard-createdat,
.file-card-container .datacard-updatedat {
  font-size: 14px;
}

.file-card-container .action-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.file-card-container .row-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.file-card-container .row-button:hover {
  background-color: var(--primary-color);
  color: white;
}

.file-card-container .icon {
  padding-right: 10px;
}

.file-data {
  height: calc(100% - 30px);
  width: 100%;
  overflow: hidden;
}
